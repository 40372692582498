.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  color: black;
}
.Pcourses {
  height: 530px;
  width: 100vw;
  background-color: #d8fdd2;
  margin: 160px 0;
  padding: 90px 0px;
}
body::-webkit-scrollbar {
  display: none;
}
.Pcourses h2 {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}
.Pcourses-boxes {
  display: flex;
  /* justify-content: space-evenly; */
  overflow-x: scroll;
  width: 100%;
}
.Pcourses-boxes::-webkit-scrollbar {
  display: none;
}
.Pcourses-box {
  border-radius: 10px;
  background: #f8fff8;
  width: 370px;
  height: 400px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 70px 50px;
  /* text-align: center; */
}

.Pcourses-box h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.Pcourses-box p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.course-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .Pcourses h2 {
    font-size: 32px;
  }
  .Pcourses-box h3 {
    font-size: 22px;
  }
}
@media screen and (max-width: 550px) {
  .Pcourses h2 {
    font-size: 30px;
  }
  .Pcourses-box h3 {
    font-size: 20px;
  }
  .Pcourses-box p {
    font-size: 18px;
  }
}
@media screen and (max-width: 482px) {
  .Pcourses-box {
    margin: 80px 20px;
    width: 80%;
  }
}
