.footer{
    background: #142712;
    padding: 50px 20px;
    color: #fff;
    margin-top: 180px;
}
.footer-flex{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.footer-left h6{
    margin: 30px 0;
    font-size: 20px;
font-style: normal;
font-weight: 400;
width: 550px;
}
.footer-right{
display: flex;
flex-direction: column;
/* justify-content: space-between; */
width: 30%;
}
.Footerlinks{
    display: flex;
    justify-content: space-between;
    color: white;
}

.Footerlinks a {
  text-decoration: none;
  color: white;
}
.footer-right h3{
    font-size: 22px;
font-style: normal;
font-weight: 500;
margin-right: 10px;
}
.socialmedia{
    display: flex;
    position: relative;
    margin-top: 30px;
    align-self: flex-end;
    
}
.icons{
    margin-left: 30px;
    width: 65px;
    height: 65px;
    text-align: center;
    background-color: #F8FFF8;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.Footerlinks{
    width: 100%;
    flex-wrap: wrap;
}
.icons img{
    width: 35px;
    height: 35px;
}
hr{
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
}
.footer p{
   text-align: center;
}
.Footerlinks h3:hover{
    cursor: pointer;
    opacity: 0.8;
}
.icons:hover{
    cursor: pointer;
    opacity: 0.8;
}
@media screen and (max-width: 1200px){
    .footer-flex{
        justify-content: space-evenly;
    }
}
@media screen and (max-width: 970px){
    .footer-left h6{
    width: 70%;
}
.footer-flex{
    align-items: flex-start;
    justify-content: space-between;
    padding-left:30px ;
}
.footer-right{
    flex-direction: column-reverse;
}
.Footerlinks h3{
    margin-right: auto;
    margin-bottom: 10px;
}
.socialmedia{
    align-self: flex-start;
}
.icons{
    margin-left: 0;
    margin-right: 30px;
}
}
@media screen and (max-width: 720px){
.footer h6{
    font-size: 18px;
}
.Footerlinks h3{
    font-size: 20px;
}
.Footerlinks{
    flex-wrap: wrap;
}
}
@media screen and (max-width: 460px){
    .footer-flex{
        padding-left:5px ;
    }
    .footer h6{
        font-size: 16px;
        margin: 20px 0;
        width: 100%;
    }
    .Footerlinks h3{
        font-size: 18px;
    }
    .socialmedia{
        margin-top: 7px;
    }
}